<div class="header-section">
    <div class="container">
        <div class="header-container">
            <div style="text-align: center; padding-top: 120px;">
                <img
                    class="header-img"
                    src="/images/illust01_2x.png"
                    alt="header"
                />
            </div>
            <div>
                <p class="header-description-txt">
                    276 HOLDINGS is a financial agency,<br />
                    specialized in also creating the visionary<br />
                    strategy, process and fluidization system<br />
                    for the SME.
                </p>
                <p class="header-description-txt-mobile">
                    276 HOLDINGS is a financial agency, specialized in also
                    creating the visionary strategy, process and fluidization
                    system for the SME.
                </p>
            </div>
            <div style="margin-top: 80px; margin-bottom: 40px">
                <div style="float: left;">
                    <hr />
                </div>
                <div class="pull-right" />
                <div class="header-description">
                    <h1 class="header-description-header-kr">돈맥경화 해소</h1>
                    <p class="header-description-txt-kr">
                        우리는 기업이 가진 가치를 발견해서 금융 자산으로 만드는
                        일을 합니다.<br />
                        <span class="background-txt"
                            >가치있는 기업의 자산, 투자를 원하는 자본</span
                        >.<br />
                        양쪽은 늘 서로를 필요로 하지만 접점을 찾지 못해 기회를 잃는
                        경우가 많습니다.<br />
                        특히 금융에 소외된 작은 기업들일수록 방법을 몰라 헤매다 안타까운
                        좌절을<br />
                        겪는 상황을 우리는 자주 목격해 왔습니다.<br />
                        이제 우리가 가진 기술과 경험으로 기업을 보다 세밀하게 들여다보고,<br
                        />
                        <span class="background-txt"
                            >새로운 가치 평가를 통해 기회를 만들어내는 금융
                            서비스를 제공</span
                        >하겠습니다.
                    </p>
                    
                    <p class="header-description-txt-kr-mobile">
                        우리는 기업이 가진 가치를 발견해서 금융 자산으로 만드는
                        일을 합니다.<br />
                        <span class="background-txt"
                            >가치있는 기업의 자산, 투자를 원하는 자본</span
                        >.<br />
                        양쪽은 늘 서로를 필요로 하지만 접점을 찾지 못해 기회를 잃는
                        경우가 많습니다.<br />
                        특히 금융에 소외된 작은 기업들일수록 방법을 몰라 헤매다 안타까운
                        좌절을 겪는 상황을 우리는 자주 목격해 왔습니다.<br />
                        이제 우리가 가진 기술과 경험으로 기업을 보다 세밀하게 들여다보고,
                        <span class="background-txt"
                            >새로운 가치 평가를 통해 기회를 만들어내는 금융
                            서비스를 제공</span
                        >하겠습니다.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
