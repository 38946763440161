<div class="content-section" style="padding-top: 110px">
    <div class="container">
        <div class="header-container" style="display: grid;">
            <div>
                <div style="float: left;">
                    <hr />
                </div>
                <div class="content-description">
                    <h3 class="content-description-title" >Vision</h3>
                    <h2 class="content-description-subtitle" >SME Finance Innovator</h2>
                    <h4 class="content-description-body" >중소기업을 위한 금융 혁신 창출</h4>
                </div>
            </div>
            <div>
                <div style="margin-top: 30px">
                    <div style="float: left;">
                        <hr />
                    </div>
                </div>
                <div class="content-description">
                    <h3 class="content-description-title" >Value</h3>
                    <h2 class="content-description-subtitle" >DO ESSENCE!</h2>
                    <h4 class="content-description-body" >
                        01<br />
                        본질적 가치를 추구하라.<br />
                        기업과 고객에게 가장 근본적으로 필요한 가치를 창출하고 제공한다.
                    </h4>
                    <h4>
                        02<br />
                        적시 / 적소 / 적확<br />
                        가장 필요한 시점에, 가장 필요한 곳에, 가장 확실한 수요를
                        연결한다.
                    </h4>
                </div>
            </div>
        </div>
    </div>
</div>
