<script>
    import Header from "../components/Header.svelte";
    import Footer from "../homeComponents/Footer.svelte";

    let modalOpenObj = {
        isTermsOfServiceModalOpen: false, // 이용약관
        isParnershipModalOpen: false, // 제휴문의
        isMarketingPolicyModalOpen: false, // 마케팅 정보 수신동의
    };

    function setModalOpenObj(name, value) {
        modalOpenObj[name] = value;
    }
</script>

<Header />
<!-- ESG Content 1 -->
<div class="esg-content1-container">
    <div class="esg-content1">
        <div class="esg-max-width">
            <img
                class="esg-content1-img"
                src="/images/esg/esg-img1.png"
                alt="esg-img1"
            />
            <div class="esg-content1-text">
                <p>지속가능한 미래를 위한 276홀딩스의 약속</p>
                <h1>ESG</h1>
            </div>
        </div>
    </div>
</div>
<!-- ESG Content 2 -->
<div class="esg-content2-container">
    <div class="esg-max-width">
        <div class="esg-content-title">
            <div>
                <hr />
            </div>
            <div>
                <h1>환경 경영</h1>
                <p>Environment Management</p>
            </div>
        </div>
        <div class="esg-content">
            <h1>
                276홀딩스는 지구 온도 상승 제한과 자연 훼손 방지에 있어
                중소기업의 금융 파트너사로서의 사명감과 책임을 인식하고
                있습니다. 이에 276홀딩스의 모든 구성원들은 기업 경영에 있어
                환경적 요소를 고려한 의사 결정 체계를 수립하여 친환경 경영
                이행을 추진할 것이며, 이를 성실히 이행하기 위해 ‘환경 경영
                행동’을 아래와 같이 선언합니다.
            </h1>
            <br />

            <h2>제 1조 환경 경영 원칙</h2>
            <span class="numbering-text">
                <p>1.</p>
                <p>
                    276홀딩스의 최고경영진은 환경 경영에 대한 책임과 권한을
                    가지며, 모든 임직원들 또한 환경 경영의 중요성을 인식하고
                    이의 실현과 실천을 위해 적극적으로 노력한다.
                </p>
            </span>
            <span class="numbering-text">
                <p>2.</p>
                <p>
                    276홀딩스는 환경 관련 법규를 준수하고 이해관계자의 견해를
                    능동적으로 수용하며, 발견된 문제에 대해서는 즉시 개선하여
                    환경 관련 리스크를 최소화 한다.
                </p>
            </span>
            <span class="numbering-text">
                <p>3.</p>
                <p>
                    기업 활동의 전반에 걸쳐 자원 절약 및 재활용, 에너지의
                    효율적인 이용을 촉진하고, 폐기물 및 환경 오염물질의 발생을
                    최소화 하여 환경 보전에 기여할 수 있도록 한다.
                </p>
            </span>
            <span class="numbering-text">
                <p>4.</p>
                <p>
                    기후 변화로 인해 촉발될 수 있는 위험과 기회 요인을 면밀히
                    분석하고 기후 변화 위기에 선제적으로 대응할 수 있는 전략을
                    수립함과 동시에 친환경 사업을 적극적으로 이행하도록 한다.
                </p>
            </span>
            <span class="numbering-text">
                <p>5.</p>
                <p>
                    환경 경영 행동 지침을 이행하는데 있어서 주기적인 검토를
                    실시하고 환경 경영 체계의 지속적 개선 및 유지 관리가 될 수
                    있도록 한다.
                </p>
            </span>
            <br />

            <h2>제 2조 에너지 효율화 및 온실가스 배출 최소화</h2>
            <span class="numbering-text">
                <p>1.</p>
                <p>
                    경영 활동에 필수적으로 소비되는 물적, 에너지 자원을
                    효율적으로 이용하는 관리 체계를 도입하고 운영함으로써
                    지속적인 환경 개선 활동을 실시한다.
                </p>
            </span>
            <br />

            <h2>제 3조 친환경 영업 정책</h2>
            <span class="numbering-text">
                <p>1.</p>
                <p>
                    영업 시 환경적 요소를 반영해서 기후 변화와 환경 개선에
                    긍정적 영향을 줄 수 있는 사업군과 고객사에 대한 서비스
                    지원을 확대해 나갈 수 있도록 한다.
                </p>
            </span>
            <span class="numbering-text">
                <p>2.</p>
                <p>
                    신재생에너지(수소에너지, 연료전지, 태양에너지, 풍력, 수력,
                    해양에너지, 바이오에너지 등) 사업 분야에 대한 협업을
                    적극적으로 검토하고, 중소기업이 저탄소 경제 체제로 전환을
                    가속화 할 수 있도록 서비스 지원을 통해 기여한다.
                </p>
            </span>
            <br />

            <h2>제 4조 환경 경영의 성과 공개</h2>
            <span class="numbering-text">
                <p>1.</p>
                <p>
                    환경 경영 활동 및 성과를 정기적(연 1회)으로 공개하고, 이를
                    통해 내∙외부 이해관계자의 의사 결정에 필요한 정보를 제공하는
                    것을 목적으로 한다
                </p>
            </span>
            <span class="numbering-text">
                <p>2.</p>
                <p>
                    환경 경영 활동 및 성과에 대한 정보 공개는 명확성, 비교
                    가능성, 일관성, 신뢰성의 요건을 만족하도록 한다.
                </p>
            </span>
            <br />

            <h2>제 5조 환경 경영 커뮤니케이션 강화</h2>
            <span class="numbering-text">
                <p>1.</p>
                <p>
                    276홀딩스 구성원들의 환경 경영 역량 강화를 위한 교육 및
                    훈련을 실시하고, 정기적으로 환경 경영 이슈에 대한
                    커뮤니케이션을 실시한다.
                </p>
            </span>
            <span class="numbering-text">
                <p>2.</p>
                <p>
                    환경 경영 관련한 대내외 이해관계자의 요구 사항과 이슈 사항을
                    파악하고, 기대를 충족시킬 전략과 대응 내역을 투명하게
                    공개한다.
                </p>
            </span>
        </div>
    </div>
</div>

<!-- ESG Content 3 -->
<div class="esg-content3-container">
    <img
        class="esg-content3-img"
        src="/images/esg/esg-img2.png"
        alt="esg-img2"
    />
    <div class="esg-max-width">
        <div class="esg-content-title">
            <div>
                <hr style="border-color: white;" />
            </div>
            <div>
                <h1>인권 경영</h1>
                <p>Business and Human Rights</p>
            </div>
        </div>
        <div class="esg-content">
            <h1>276홀딩스는 다음의 경영 헌장을 지키겠습니다.</h1>
            <br />
            <span class="numbering-text">
                <h2 class="content-font-weight-300">1.</h2>
                <h2 class="content-font-weight-300">
                    인간의 존엄과 가치를 존중하고 인권보호 및 향상을 위해
                    노력한다.
                </h2>
            </span>
            <span class="numbering-text">
                <h2 class="content-font-weight-300">2.</h2>
                <h2 class="content-font-weight-300">
                    성별, 연령, 인종, 장애, 종교를 이유로 고용, 승진, 교육
                    등에서 일체의 차별을 금지하며 다양성을 존중한다.
                </h2>
            </span>
            <span class="numbering-text">
                <h2 class="content-font-weight-300">3.</h2>
                <h2 class="content-font-weight-300">
                    모든 형태의 강제노동, 폭행, 갑질, 차별, 성희롱·성폭력을
                    금지한다.
                </h2>
            </span>
            <span class="numbering-text">
                <h2 class="content-font-weight-300">4.</h2>
                <h2 class="content-font-weight-300">
                    산업계, 지역사회, 기업 종사자 등 모든 이해관계자의 인권
                    보호를 위해 노력하고 소통한다.
                </h2>
            </span>
            <span class="numbering-text">
                <h2 class="content-font-weight-300">5.</h2>
                <h2 class="content-font-weight-300">
                    국내외 환경 관련 법규를 준수하고 우리의 사업과 업무 방식을
                    통해 환경을 보호하고 개선 시키도록 노력한다.
                </h2>
            </span>
            <span class="numbering-text">
                <h2 class="content-font-weight-300">6.</h2>
                <h2 class="content-font-weight-300">
                    경영 및 영업 활동에 있어서 업무상 수집한 개인정보를
                    보호한다.
                </h2>
            </span>
            <span class="numbering-text">
                <h2 class="content-font-weight-300">7.</h2>
                <h2 class="content-font-weight-300">
                    노동원칙을 준수하며 안전하고 건강한 근무환경을 제공한다.
                </h2>
            </span>
            <span class="numbering-text">
                <h2 class="content-font-weight-300">8.</h2>
                <h2 class="content-font-weight-300">
                    경영 및 연구활동에서 발생할 수 있는 인권침해에 대하여
                    신속하고 적절한 구제조치를 제공하고 사전예방을 위하여
                    노력한다.
                </h2>
            </span>
            <span class="numbering-text">
                <h2 class="content-font-weight-300">9.</h2>
                <h2 class="content-font-weight-300">
                    공정하게 거래하며 회원사, 협력사, 지역사회 등 이해관계자와
                    사회적 약자(여성, 장애인, 이주민 등)의 인권을 보호한다.
                </h2>
            </span>
        </div>
    </div>
</div>

<!-- ESG Content 4 -->
<div class="esg-content4-container">
    <img
        class="esg-content4-img"
        src="/images/esg/esg-img3.png"
        alt="esg-img3"
    />
    <div class="esg-max-width">
        <div class="esg-content-title content4-title">
            <div>
                <hr />
            </div>
            <div>
                <h1>윤리 경영</h1>
                <p>Ethical Management</p>
            </div>
        </div>
        <div class="esg-content content4">
            <h1>
                276홀딩스는 투명하고 공정한 윤리경영을실천하고자 다음과 같이
                선언합니다
            </h1>
            <br />
            <h2 class="content-font-weight-300 content4-font-size">
                하나, 사업 활동에서 관련법규를 준수하고, 투명하고 공정한 거래를
                통해 경쟁 우위를 확보한다.
            </h2>
            <h2 class="content-font-weight-300 content4-font-size">
                둘, 고객의 가치 증대와 만족 향상을 위해 최선의 노력을 다한다.
            </h2>
            <h2 class="content-font-weight-300 content4-font-size">
                셋, 수집한 고객 및 회사의 자산과 정보를 보호한다.
            </h2>
            <h2 class="content-font-weight-300 content4-font-size">
                넷, 구성원 간의 상호간 존중과 신뢰를 바탕으로 건전한 기업문화를
                만들어 나아간다.
            </h2>
            <h2 class="content-font-weight-300 content4-font-size">
                다섯, 안전한 근무환경을 만들고 환경을 유지하기 위해 교육과 개선
                활동에 적극적으로 참여한다.
            </h2>
        </div>
    </div>
</div>

<Footer {setModalOpenObj} />
