<div class="footer-section" style="margin-top: 100px">
    <div class="header-container" style="text-align: center;">
        <h3><a id="Partners">Partners</a></h3>
        <div class="row partner-container">
            <div class="col-md-3 col-6 partner-item">
                <img class="partner-img" src="/images/logo_magna_invest_2x.png" alt="partner1">
            </div>
            <div class="col-md-3  col-6 partner-item">
                <img class="partner-img" src="/images/logo_ksi_2x.png" alt="partner2">
            </div>
            <div class="col-md-3  col-6 partner-item">
                <img class="partner-img" src="/images/logo_infobank_CI02.png" alt="partner2" style="width: 150px">
            </div>
            <div class="col-md-3  col-6 partner-item">
                <img class="partner-img" src="/images/logo_sopoongventures_CI02.png" alt="partner2">
            </div>
            <div class="col-md-3 col-6 partner-item">
                <img class="partner-img" src="/images/logo_신한_FUTURE'S_LAB.png"  alt="partner7">
            </div>
            <div class="col-md-3 col-6 partner-item">
                <img class="partner-img" src="/images/logo_아시아에프앤아이.png"  alt="partner8">
            </div>
            <div class="col-md-3 col-6 partner-item" style="justify-content: center;">
                <img class="partner-img" src="/images/logo_IBK_창공.png"  alt="partner9">
            </div>
            <div class="col-md-3 col-6 partner-item">
                <img class="partner-img" src="/images/logo_gb_2x.png" alt="partner5">
            </div>
            <div class="col-md-3 col-6 partner-item">
                <img class="partner-img" src="/images/logo_elite_2x.png" alt="partner3">
            </div>
            <div class="col-md-3 col-6 partner-item">
                <img class="partner-img" src="/images/logo_dowools_2x.png" alt="partner4">
            </div>
            <div class="col-md-3 col-6 partner-item">
                <img class="partner-img" src="/images/logo_수이제너리스.webp" alt="partner5">
            </div>
            <div class="col-md-3 col-6 partner-item">
                <img class="partner-img" src="/images/logo_FLOW_PARTNERS.png"  alt="partner6">
            </div>
        </div>
    </div>
</div>
