<script>
    import HeaderSection from "../homeComponents/HeaderSection.svelte";
    import ValueCreators from "../homeComponents/ValueCreators.svelte";
    import VisionValue from "../homeComponents/VisionValue.svelte";
    import FlowPoint from "../homeComponents/FlowPoint.svelte";
    import News from "../homeComponents/News.svelte";
    import Partners from "../homeComponents/Partners.svelte";
    import Members from "../homeComponents/Members.svelte";
    import Map from "../homeComponents/Map.svelte";
    import Footer from "../homeComponents/Footer.svelte";
    import TermsOfServiceModal from "../homeComponents/modal/footer/TermsOfServiceModal.svelte";
    import MarketingPolicyModal from "../homeComponents/modal/footer/MarketingPolicyModal.svelte";
    import ParnershipModal from "../homeComponents/modal/footer/ParnershipModal.svelte";
    import Header from "../components/Header.svelte";
    

    $: innerWidth = 0; // 브라우저 innerWidth

    let modalOpenObj = {
        isTermsOfServiceModalOpen: false, // 이용약관
        isParnershipModalOpen: false, // 제휴문의
        isMarketingPolicyModalOpen: false, // 마케팅 정보 수신동의
    };

    function setModalOpenObj(name, value) {
        modalOpenObj[name] = value;
    }

    let is_open = false;

    function headerButtonClick() {
        is_open = !is_open;
    }
</script>

<svelte:window bind:innerWidth />

<main>
    <Header />

    <HeaderSection />

    <ValueCreators />

    <VisionValue />

    <FlowPoint />

    <Members {innerWidth} />

    <News />

    <Partners />

    <!--<Map />
        기존 존재하던 Map 컴포넌트 07.26 삭제 - 박이사님 요청사항
    <div class="footer">Copyright© by 276Holdings. All rights reserved.</div>-->

    <Footer {setModalOpenObj} />

    <TermsOfServiceModal
        isOpen={modalOpenObj.isTermsOfServiceModalOpen}
        {setModalOpenObj}
    />
    <!--<ParnershipModal isOpen={modalOpenObj.isParnershipModalOpen} setModalOpenObj={setModalOpenObj} />-->
    <MarketingPolicyModal
        isOpen={modalOpenObj.isMarketingPolicyModalOpen}
        {setModalOpenObj}
    />
</main>
