<script>
    export let width = "550";
    export let height = "550";
</script>

<svg xmlns="http://www.w3.org/2000/svg" id="outline" width="100px" viewBox="-20 -10 {width} {height}">
    <circle cx="112" cy="128" r="8"/>
    <circle cx="136" cy="128" r="8"/>
    <circle cx="160" cy="128" r="8"/>
    <path d="M384,168a8.00008,8.00008,0,0,0-8-8H344a8.00008,8.00008,0,0,0-8,8V288H320V200a8.00008,8.00008,0,0,0-8-8H280a8.00008,8.00008,0,0,0-8,8v88H256V224a8.00008,8.00008,0,0,0-8-8H216a8.00008,8.00008,0,0,0-8,8v64H192V248a8.00008,8.00008,0,0,0-8-8H152a8.00008,8.00008,0,0,0-8,8v40H120v16H400V288H384ZM160,288V256h16v32Zm64,0V232h16v56Zm64,0V208h16v80Zm64,0V176h16V288Z"/><path d="M120,208v16h40a8.00206,8.00206,0,0,0,3.31055-.71729L337.73242,144H392V128H336a8.00206,8.00206,0,0,0-3.31055.71729L158.26758,208Z"/><path d="M488,40H24a8.00008,8.00008,0,0,0-8,8V336a8.00008,8.00008,0,0,0,8,8H192v48H176v16H336V392H320V344H488a8.00008,8.00008,0,0,0,8-8V48A8.00008,8.00008,0,0,0,488,40ZM424,328H88V112a8.00917,8.00917,0,0,1,8-8H416a8.00917,8.00917,0,0,1,8,8ZM304,392H208V344h96Zm176-64H440V112a24.0275,24.0275,0,0,0-24-24H96a24.0275,24.0275,0,0,0-24,24V328H32V56H480Z"/><path d="M368,424H144a8.00008,8.00008,0,0,0-8,8v24H120v16H392V456H376V432A8.00008,8.00008,0,0,0,368,424ZM216,440h80v16H216Zm-16,16H184V440h16Zm112-16h16v16H312Zm-160,0h16v16H152Zm192,16V440h16v16Z"/>
    <path d="M480.96973,429.65625a46.62744,46.62744,0,0,0-65.94043.00049l-12.68653,12.68652a7.99983,7.99983,0,0,0-1.498,9.23438l8,16A7.99869,7.99869,0,0,0,416,472h64a7.99869,7.99869,0,0,0,7.15527-4.42236l8-16a7.99983,7.99983,0,0,0-1.498-9.23438ZM475.05566,456H420.94434l-3.21-6.42041,8.60839-8.60889a30.6274,30.6274,0,0,1,43.31348-.00049l8.60937,8.60938Z"/>
</svg>
