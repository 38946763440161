<div class="img-section">
    <div class="img-section1">
        <div class="container" style="display: flex;">
            <img
                class="content-img1"
                src="/images/illust02_2x.png"
                alt="content1"
            />
            <h1 class="content-img1-txt">
                Value<br />Creators
            </h1>
        </div>
    </div>
</div>