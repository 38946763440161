<script>
    import BusinessCssIcon from "./icon/BusinessCssIcon.svelte";
    import RentalIcon from "./icon/RentalIcon.svelte";
    import CommonIcon from "./icon/CommonIcon.svelte";

    let data = {
        userCount: 0,
        receivableCount: 0,
        receivableAmount: 0,
    };
    async function getBusinessOverview() {
        try {
            const returnValue = await fetch(
                `https://www.flowpay.kr/api/v2/main`
            );
            const response = await returnValue.json();
            data = response?.data?.platformStatus;
        } catch (error) {
            console.error(error);
        }
    }

    getBusinessOverview();
</script>

<div class="img-section img-section-service" style="overflow: hidden;">
    <div class="img-section2">
        <div class="container" style="position: relative;">
            <img
                class="content-img2"
                src="/images/illust03_2x.png"
                alt="content2"
            />
            <div class="img-section2-header-container">
                <div class="img-section2-header-content1">
                    <h3 class="white-txt">Service</h3>
                    <div style="display: flex; align-items: center;">
                        <img
                            class="a-flowpay-logo"
                            src="/images/FlowPoint_BI_White.svg"
                            alt="platform"
                        />
                        <a
                            class="a-flowpay"
                            href="https://www.flowpay.kr/main/"
                            target="_blank"
                            rel="noreferrer">바로가기</a
                        >
                    </div>
                    <h4 class="white-txt">
                        매출채권의 새로운 흐름을 만들어내는 기업 금융 서비스 -
                        플로우포인트<br />
                        매출 계약서를 전자문서로 만들어 다양하게 활용해 보세요.
                    </h4>
                </div>
                <div class="row">
                    <div
                        class="d-md-none"
                        style="display: flex; flex-wrap:wrap;"
                    >
                        <div
                            class="col-4 intro-item"
                            style="text-align: center;"
                        >
                            <!-- d-md-none -->
                            <img
                                class="service-img-item d-md-none"
                                src="/images/icon01_2x.png"
                                alt="service1"
                            />
                            <h4
                                class="white-txt d-md-none"
                                style="word-break: keep-all;"
                            >
                                매출채권의 발행/수취/양도 원스톱 관리
                            </h4>
                        </div>
                        <div
                            class="col-4 intro-item"
                            style="text-align: center;"
                        >
                            <img
                                class="service-img-item d-md-none"
                                src="/images/icon02_2x.png"
                                alt="service2"
                            />
                            <h4
                                class="white-txt d-md-none"
                                style="word-break: keep-all;"
                            >
                                매출채권 유동화를 위한 중개자 연결
                            </h4>
                        </div>
                        <div
                            class="col-4 intro-item"
                            style="text-align: center;"
                        >
                            <img
                                class="service-img-item d-md-none"
                                src="/images/icon03_2x.png"
                                alt="service3"
                            />
                            <h4
                                class="white-txt d-md-none"
                                style="word-break: keep-all;"
                            >
                                기타 매출채권 관련 부가 서비스 제공
                            </h4>
                        </div>
                        <div
                            class="col-12 intro-item row a-flowpay-container d-md-none"
                        >
                            <a
                                class="a-flowpay-contents"
                                href="https://www.flowpay.kr/main/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <RentalIcon />
                                <!-- d-none d-md-block === PC -->
                                <div>
                                    <h3 class="title hover">
                                        산업용 기계 렌탈
                                    </h3>
                                    <h4 class="body hover">
                                        신규 기계 구매, 기존 기계 활용 전액 승인
                                        가능.<br />
                                        보증금 없이 부담없이 최장 48개월 간
                                    </h4>
                                </div>
                            </a>

                            <a
                                class="a-flowpay-contents"
                                href="https://www.flowpay.kr/main/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <BusinessCssIcon />
                                <div>
                                    <h3 class="title hover">
                                        기업 평가 서비스
                                    </h3>
                                    <h4 class="body hover">
                                        전자어음 할인 등 중소기업 채권 유동화 시<br
                                        />
                                        참고할 수 있는 등급 및 금리 제공
                                    </h4>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div
                        class="col-12 intro-item row white-txt"
                        style="text-align:center; padding: 0;"
                    >
                        <h3
                            class="col-12 platform-overview d-md-none"
                            style="margin-top: 70px;"
                        >
                            Platform Overview
                        </h3>
                        <div class="col-12">
                            <h4 style="margin: 0; font-weight:200;">
                                누적 채권거래액
                            </h4>
                            <span class="overview-data-span">
                                <h1 style="margin: 0;">
                                    {data.receivableAmount
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </h1>
                                <p>원</p>
                            </span>
                        </div>
                        <div class="col-12 data-table-container">
                            <table
                                class="table w-100 text-center m-0 data-table"
                            >
                                <tbody>
                                    <!-- PC -->
                                    <tr class="row white-txt d-none d-md-flex">
                                        <td class="col table-light-half-border">
                                            <h4>채권 거래건수</h4>
                                            <span class="overview-data-span">
                                                <h1>
                                                    {data.receivableCount
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                </h1>
                                                <p>건</p>
                                            </span>
                                        </td>
                                        <td class="col table-light-half-border">
                                            <h4>누적 기업고객</h4>
                                            <span class="overview-data-span">
                                                <h1>
                                                    {data.userCount
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                </h1>
                                                <p>개사</p>
                                            </span>
                                        </td>
                                        <td class="col" style="border: none">
                                            <h4>재이용율</h4>
                                            <span class="overview-data-span">
                                                <h1>61</h1>
                                                <p>%</p>
                                            </span>
                                        </td>
                                    </tr>
                                    <!-- Mobile -->
                                    <tr
                                        class="row white-txt d-md-none table-mobile"
                                        style="border: 1px solid white;"
                                    >
                                        <td class="col">
                                            <h4>채권 거래건수</h4>
                                            <span class="overview-data-span">
                                                <h1 style="margin: 0;">
                                                    {data.receivableCount
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                </h1>
                                                <p>건</p>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        class="row white-txt d-md-none table-mobile"
                                        style="border: 1px solid white;"
                                    >
                                        <td class="col">
                                            <h4>누적 기업고객</h4>
                                            <span class="overview-data-span">
                                                <h1 style="margin: 0;">
                                                    {data.userCount
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                </h1>
                                                <p>개사</p>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        class="row white-txt d-md-none table-mobile"
                                        style="border: 1px solid white;"
                                    >
                                        <td class="col" style="border: none">
                                            <h4>재이용율</h4>
                                            <span class="overview-data-span">
                                                <h1 style="margin: 0;">61</h1>
                                                <p>%</p>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="d-none d-md-flex services-container">
                        <div class="services-div">
                            <CommonIcon
                                name="FLOWPOINT"
                                width="160"
                                height="40"
                                viewBox="315 240 210 110"
                            />
                            <p class="service-title">
                                매출채권 전자문서 관리 플랫폼
                            </p>
                            <div />
                            <p class="service-description">
                                매출 계약을 진행하시나요? <br />
                                종이에 도장찍는 계약서는 이제 그만! <br />
                                지금 플로우포인트에서 전자 계약을 체결하시면
                                <br />
                                온라인 문서, 일정 관리와 양수도가 가능한 <br />
                                유동화 채권이 생깁니다.
                            </p>
                        </div>

                        <div class="services-div">
                            <CommonIcon
                                name="FLOWPAY"
                                width="160"
                                height="40"
                                viewBox="360 240 210 110"
                            />
                            <p class="service-title">기업 전용 BNPL 서비스</p>
                            <div />
                            <p class="service-description">
                                매출에 필요한 각종 원자재, 조달 물품 등을 <br />
                                플로우페이가 먼저 결제해서 납품해 드립니다.
                                <br />
                                고객사는 최종 제품 판매 후 추후 협의된 기일에
                                <br />
                                결제해 주시면 됩니다.
                            </p>
                        </div>

                        <div class="services-div">
                            <CommonIcon
                                name="FLOWSNR"
                                width="160"
                                height="40"
                                viewBox="350 240 210 110"
                            />
                            <p class="service-title">
                                기업 동산 세일 & 렌탈백 서비스
                            </p>
                            <div />
                            <p class="service-description">
                                기업 동산 리스를 새롭게! <br />
                                보증금 30% 부담을 줄이고, 신규 구매 기기 <br />
                                뿐만 아니라 기존에 활용할 수 없었던 중고 기기
                                <br />
                                및 설비도 모두 현금 자산으로 활용할 수 있습니다.
                            </p>
                        </div>

                        <div class="services-div">
                            <CommonIcon
                                name="FLOWSCORE"
                                width="180"
                                height="40"
                                viewBox="325 240 210 110"
                            />
                            <p class="service-title">
                                단기 매출채권 부실 평가 정보
                            </p>
                            <div />
                            <p class="service-description">
                                거래중이거나 예정인 매출처의 정보가 <br />
                                막막하신가요? 너무 방대하고 장기적인 관점의
                                <br />
                                자료보다 바로 지금 상황이 어떤지 확인하실 수
                                <br />
                                있는 단기 매출 평가 정보를 제공해 드립니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
