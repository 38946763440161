<script>
    export let name='';
    export let width=100;
    export let height=100;
    export let color = 'black';
    export let viewBox = '0 0 500 500';
    export let style = '';

    let link = '';
    let svgContent = ''; // SVG 파일 내용을 저장할 변수 추가
    let gContent = '';

    switch (name) {
        case 'FLOWPOINT':
            link ='/images/FLOWPOINT.svg';
            break;
        case 'FLOWPAY':
            link ='/images/FLOWPAY.svg';
            break;
        case 'FLOWSCORE':
            link ='/images/FLOWSCORE.svg';
            break;
        case 'FLOWSNR':
            link ='/images/FLOWSNR.svg';
            break;
        default:
            break;
    }
    // SVG 파일 내용을 가져오기 위한 비동기 함수
    async function fetchSvgContent() {
        const response = await fetch(link);
        svgContent = await response.text();

        // SVG 문자열을 DOM 객체로 파싱합니다.
    const parser = new DOMParser();
    
    const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');

    //// <g> 태그를 찾습니다.
    const gElement = svgDoc.querySelector('g');
    gContent = new XMLSerializer().serializeToString(gElement);
    }

    // 컴포넌트가 생성될 때 SVG 파일 내용을 가져오도록 호출
    fetchSvgContent();
</script>


<svg
  xmlns="http://www.w3.org/2000/svg"
  width={`${width}px`}
  height={`${height}px`}
  fill={color}
  viewBox={viewBox}
  style={style}
>
    <!-- 아이콘의 SVG 경로 데이터 -->
    {@html gContent}
</svg>