
<!-- Modal.svelte -->
<script>
    export let isOpen;
    export let onClose;
    export let width;
    export let height;
  
    // 모달이 열려있는지 여부를 상태로 관리합니다.
    // 모달이 열려있는 동안에는 다른 내용과 상호작용이 막히도록 클래스를 추가합니다.
    let showModal = false;
  
    // isOpen 값이 변경되면 showModal 상태를 업데이트합니다.
    // 모달이 열리거나 닫힐 때 애니메이션 효과를 위해 setTimeout을 사용합니다.
    $: {
      showModal = isOpen;
      if (isOpen) {
        setTimeout(() => (showModal = true), 10);
      } else {
        setTimeout(() => (showModal = false), 300);
      }
    }
  
    // 모달이 닫히는 애니메이션의 종료 이벤트를 처리합니다.
    // 모달이 완전히 닫힌 후에 onClose 콜백을 호출합니다.
    function handleAnimationEnd() {
      if (!isOpen) {
        onClose();
      }
    }
  </script>
  
  {#if showModal}
    <div class="modal-overlay" on:click={() => onClose()}></div>
    <div class="modal-content" style={`width: ${width ? (width+'px') : ('50%') }; height: ${height ? (height+'px') : ('90%') };`}
         on:animationend={() => handleAnimationEnd()}>
         <img 
            src="/images/cancel.png"
            alt="platform"
            style="width: 20px; position:absolute; right: 12px; top: 12px; cursor:pointer"
            on:click={() => onClose()}
        />
      <!-- 모달의 내용을 여기에 추가하세요 -->
      <slot></slot>
    </div>
  {/if}
  

  <style>
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }
  
    .modal-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        padding: 20px;
        z-index: 1000;
        animation: fadeInOut 0.3s;
    }
  
    @keyframes fadeInOut {
        from {
            opacity: 0;
            }
        to {
            opacity: 1;
        }
    }
  </style>