<script>
    import Modal from '../Modal.svelte';
    
    export let isOpen
    export let setModalOpenObj
    
    const onCloseHandler = () => {
      setModalOpenObj('isMarketingPolicyModalOpen', false)
    };
  </script>
  
  <div>
    {#if isOpen}
      <Modal isOpen={isOpen} onClose={onCloseHandler}>
        <div class="modal_content_div">
            <h3> 마케팅 정보 수집 이용 약관 </h3>
            <p>제1장 총칙</p>
            <p>제1조 (목적)</p>
            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    이 약관은 주식회사 276홀딩스(이하 “회사”라 합니다)가 운영하는
                    인터넷 사이트(https://www.276holdings.com)(이하 “사이트”라 합니다)를 통하여
                    제공하는 모든 웹 또는 모바일 서비스(이하 ‘서비스’)를 이용함에
                    있어, “회사”와 “회원” 간의 권리와 의무, 책임사항 및 “회원”의
                    서비스 이용절차에 관한 사항을 규정함을 목적으로 합니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>②</p>
                <p class='modal_indent_content'>
                    “회사”는 시스템에 관한 제반 기술과 운영에 대한 모든 권한을 갖고
                    있으며, “회사”가 “회원”에게 제공하는 대출심사, 대출계약,
                    대출실행 및 채권관리(추심) 업무(이하 “여신업무 등”이라 합니다)는
                    “회사”와 제휴한 “연계 금융회사”가 전담합니다.
                </p>
            </div>

            <p>제2조 (약관의 적용범위)</p>
            <p class='modal_indent'>
                이 약관은 “회사”가 인터넷 상으로 제공하는 모든 서비스의 이용절차
                및 기타 필요한 사항에 적용됩니다.
            </p>

            <p>제3조 (용어의 정의)</p>
            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                </p>
            </div>
            <div class="modal_indent_x2_div">
                <p class='modal_indent'>1.</p>
                <p class='modal_indent_content'>
                    “사이트”란 회사가 서비스 또는 용역을 회원에게 제공하기 위하여
                    컴퓨터 등 정보통신설비를 이용하여 제공할 수 있도록 설정한 가상의
                    영업장 또는 서비스 공간을 말하며, 회원 계정(ID 및 PASSWORD)을
                    이용하여 서비스를 제공받을 수 있는 아래의 사이트를 말합니다.
                    아울러 사이트를 운영하는 사업자의 의미로도 사용합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>2.</p>
                <p class='modal_indent_content'>
                    “서비스”라 함은 “회사”의 홈페이지 및 “회사”가 직접 운영하는 웹
                    또는 모바일에서 제공하는 인터넷상의 모든 서비스를 말합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>3.</p>
                <p class='modal_indent_content'>
                    “회원”이라 함은 이 약관을 승인하고 회사와 서비스 이용계약을
                    체결한 자를 말합니다. P2P 대출의 지위에 따라 차입자와 투자자로
                    구분되며, 회사가 제시하는 정책에 의하여 회원의 등급을 구분하여
                    서비스 이용범위나 혜택 또는 서비스 수수료 징수 등을 다르게
                    적용할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>4.</p>
                <p class='modal_indent_content'>
                    “투자자”라 함은 차입자에 대한 대출채권으로부터 발생하는 원리금을
                    수취할 수 있는 권리를 매입하려는 자이며, 사이트에 공시된
                    전자어음대출채권에 대해 투자신청 및 투자금의 제공을 한 회원을
                    말합니다. 회사는 “투자자” 회원을 개인 일반투자자, 소득요건을
                    구비한 개인투자자, 개인 전문투자자, 법인투자자로 구분합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>5.</p>
                <p class='modal_indent_content'>
                    “차입자”라 함은 투자자 자금을 기초로 사이트를 통해 대출을
                    받으려는 자이며, 사이트에서 제공되는 양식에 따라
                    전자어음대출신청을 함으로써 연계 금융회사와 사이에
                    전자어음대출계약을 체결한 자를 말합니다. 사이트에서 사용하는
                    “의뢰인”, “대출자”이라는 용어는 차입자와 동일한 의미를 가지고
                    있습니다. 회사는 “차입자” 회원을 다시 개인사업자 차입자 회원,
                    법인사업자 차입자 회원으로 구분합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>6.</p>
                <p class='modal_indent_content'>
                    “대출채권”이라 함은 연계 금융회사가 대출심사를 통과한 차입자와
                    사이에 전자어음대출계약을 체결함에 따라 취득하게 되는 차입자에
                    대한 채권을 말합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>7.</p>
                <p class='modal_indent_content'>
                    “직거래”라 함은 투자자와 차입자가 회사가 제공하는 절차에 의하지
                    않고 직접 거래하는 행위를 말합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>8.</p>
                <p class='modal_indent_content'>
                    “투자금”이라 함은 투자자가 대출채권의 원리금수취권을 취득하기
                    위하여 회사에게 지급하는 금전을 말합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>9.</p>
                <p class='modal_indent_content'>
                    “원리금수취권”이라 함은 투자자가 대출채권에 투자함으로써 연계
                    금융회사가 차입자로부터 원리금을 회수하는 경우에 한하여 투자금에
                    비례한 원금 및 일정율의 수익금을 연계 금융회사로부터 지급받을 수
                    있는 권리를 말합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>10.</p>
                <p class='modal_indent_content'>
                    “연계 금융회사”라 함은 회사와 특정한 제휴 및 협약을 맺고
                    차입자에 대한 대출심사, 대출계약, 대출실행 및 채권관리(추심)
                    등에 관한 업무(이하 “여신업무 등”이라 합니다)를 수행하는 법인을
                    말하며, “주식회사 한국기업금융대부”가 회사와 연계하여 “여신업무
                    등”을 수행합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>11.</p>
                <p class='modal_indent_content'>
                    “스크래핑(SCRAPING) 서비스”라 함은 회원이 사용하는 인터넷
                    접근매체 정보를 이용하여 회원이 동의한 정보를 한 화면에서 조회
                    및 관리할 수 있는 서비스를 말합니다. 이때 회원의 정보는 회원
                    본인이 스크래핑 서비스를 이용하여 해당 기관 인터넷서비스에
                    로그인한 후 선택하여 불러온 정보를 말합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>12.</p>
                <p class='modal_indent_content'>
                    “전자어음”이라 함은 「전자어음의 발행 및 유통에 관한 법률」에
                    의거 발행된 어음을 말하며, 전자상업어음 및 전자기타어음으로
                    구분합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>13.</p>
                <p class='modal_indent_content'>
                    “전자상업어음”이라 함은 채무자가 사업자등록증을 교부 받은 업체와
                    사업목적에 부합한 경상적인 영업활동으로 이루어지는 재화 및
                    용역의 거래와 관련하여 수취한 전자어음을 말합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>14.</p>
                <p class='modal_indent_content'>
                    “전자기타어음”이라 함은 채무자가 수취한 전자어음 중 전항의
                    전자상업어음을 제외한 전자어음을 말합니다.
                </p>
            </div>
            <br/>
            <br/>
            <p>제2장 서비스의 종류 및 이용</p>
            <p>제4조 (서비스의 종류)</p>
            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    회사가 회원에게 제공하는 서비스는 다음 각 호와 같습니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>1.</p>
                <p class='modal_indent_content'>
                    전자상업어음 기반 P2P 대출정보 중개 서비스(“플랫폼”): 차입자가
                    사이트를 통하여 연계 금융회사로부터 대출을 받기 위한 대출 신청,
                    투자자의 실행 등 투자와 관련된 모든 활동에 대하여 온라인으로
                    제공하는 서비스 및 기타 이용 서비스, 관련 부가서비스 일체를
                    말합니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>2.</p>
                <p class='modal_indent_content'>
                    기타 서비스 회사의 “전자상업어음 기반 P2P 대출정보 중개
                    서비스”이외에 “회사”의 사이트를 통하여 제공하는 어음닥터 등
                    홍보서비스 기타의 서비스를 말합니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>②</p>
                <p class='modal_indent_content'>
                    회사는 서비스 변경 시에 그 변경되는 서비스의 내용 및 제공일자를
                    제10조에서 정한 방법으로 회원에게 통지하고, 전 제1항에서 정한
                    서비스를 변경하여 제공할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>③</p>
                <p class='modal_indent_content'>
                    회사는 회원 간의 직거래로 인하여 발생한 문제에 대하여 책임지지
                    않습니다.
                </p>
            </div>

            <p>제5조 (회원 자격의 정지, 서비스 이용 제한 및 회원의 등급)</p>

            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    회사는 서비스를 통한 거래의 안전성과 신뢰성을 위하여 이 약관에서
                    정한 바에 따라 회원자격을 정지하거나 서비스의 이용을 제한할 수
                    있습니다. 회원자격이 정지된 회원은 모든 서비스를 이용할 수
                    없습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>②</p>
                <p class='modal_indent_content'>
                    회사는 회원에 대하여 과거의 거래 등을 분석하여 등급을 부여할 수
                    있습니다. 회사는 회원의 등급에 따라 거래이용의 혜택을
                    부여하거나, 회원자격을 정지하거나 일정한 대출 또는 투자 서비스의
                    이용을 제한할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>1.</p>
                <p class='modal_indent_content'>
                    차입자와 투자자 등급: 회사는 각 회원의 거래에 따른
                    전자상업어음에 의한 상환기록 및 투자 최종 성사 여부 등을
                    종합하여 등급을 각각 부여합니다. 등급의 산정기준과 운영정책에
                    관한 세부사항은 사이트의 공지란을 통하여 별도로 공지합니다.
                    회사는 회원의 등급에 따라 수수료의 할인 등 거래이용의 혜택을
                    부여할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>2.</p>
                <p class='modal_indent_content'>
                    회원 자격의 정지 또는 서비스 이용 제한: 회사는 서비스의 안정,
                    거래의 신뢰성과 거래질서 유지를 위하여 회원의 등급 및 평점에
                    따라 당해 회원의 자격을 일시 정지하거나 영구 정지할 수 있습니다.
                    회사는 허위 거래등록 등 거래진행을 방해하는 부당한 거래행위,
                    직거래 유도행위 등에 대하여 회원 자격을 정지할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>3.</p>
                <p class='modal_indent_content'>
                    서비스 이용정지 등의 해소: 회사는 등급에 따라 회원 자격이
                    정지되거나 서비스 이용이 제한된 회원이 해당 행위를 할 수 밖에
                    없었던 사유를 소명하는 등 회사가 정하는 기준을 충족하는 경우
                    회원자격 정지 또는 서비스 이용제한 조치를 해소할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>4.</p>
                <p class='modal_indent_content'>
                    회원에 대한 혜택: 회사는 대출 또는 투자 서비스를 성실하고
                    적법하게 이용하여 회원상호간의 원활한 거래에 기여한 회원에
                    대하여 각종 혜택을 부여합니다. 혜택의 종류와 범위, 부여기준 등은
                    회사의 별도 공지에 따릅니다.
                </p>
            </div>

            <div class="modal_indent_x2_div">
                <p class='modal_indent'>5.</p>
                <p class='modal_indent_content'>
                    재가입의 경우: 회원이 이용계약해지 후 재가입하는 경우에도
                    이용계약해지 전 부과된 등급 및 그에 기하여 회사로부터 부과 받은
                    회원자격정지 등의 조치는 그대로 유지됩니다. 이는 회사 정책에
                    따라 부여된 등급 및 제재에 관한 사항을 의도적으로 회피할
                    목적으로 탈퇴 후 재가입절차를 이용하려는 회원을 미연에
                    방지하고자 함입니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>③</p>
                <p class='modal_indent_content'>
                    회사는 회원에 대하여 과거의 거래 등을 분석하여 등급을 부여할 수
                    있습니다. 회사는 회원의 등급에 따라 거래이용의 혜택을
                    부여하거나, 회원자격을 정지하거나 일정한 대출 또는 투자 서비스의
                    이용을 제한할 수 있습니다.
                </p>
            </div>

            <p>제6조 (거래보호 서비스)</p>
            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    회사는 서비스를 제공하는 과정에서 대금의 수령, 보관 및 송금업무,
                    연계 금융회사로부터 원리금수취권 매매, 대행 및 대금수령으로
                    이루어지는 거래보호서비스를 제공합니다. 이러한 거래보호서비스는
                    인터넷을 통하여 익명으로 이루어지는 회사와 회원 간 또는 회원
                    상호간의 거래의 안전성과 신뢰성을 도모하고 보호하기 위한
                    목적에서 제공하는 장치입니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>②</p>
                <p class='modal_indent_content'>
                    회원이 거래보호서비스를 통하지 않는 직거래를 유도하는 경우
                    회사는 게시물 삭제, 거래중지 등 기타 필요한 조치를 취할 수
                    있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>③</p>
                <p class='modal_indent_content'>
                    거래보호서비스의 일환으로 이루어지는 대금보관으로 인하여 회사가
                    취득하는 이자 등은 서비스 제공의 대가이므로 회원은 회사에 대하여
                    당해 이자 등의 반환을 청구할 수 없고, 대금송금 또는 출금으로
                    인하여 발생하는 수수료는 대금을 송금 또는 출금하는 회원이 부담할
                    수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>④</p>
                <p class='modal_indent_content'>
                    회사는 대금 수령의 안전성을 도모하기 위해 투자자의 예치금을
                    회사와 협업관계에 있는 금융 회사의 예치 계좌로 이체합니다.
                    회사는 투자자의 투자금 이체 또는 투자자의 출금 요청에 의한
                    예치금 이체 등의 요청이 없는 한 임의로 해당 예치금을 활용 할 수
                    없습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>⑤</p>
                <p class='modal_indent_content'>
                    회사가 제공하는 거래보호서비스를 이용하지 않은 거래 및 자금과
                    관련하여 발생한 모든 사항은 차입자와 투자자 간의 상호협의를 통해
                    해결하여야 하며, 회사는 이에 대해 일체 책임을 지지 않습니다.
                </p>
            </div>

            <p>제7조 (스크래핑 서비스 이용의 제한)</p>
            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    스크래핑 서비스 대상 기관의 인터넷서비스 이용시간의 제한,
                    전산장애, 서비스 제한 등의 경우에는 서비스의 일부만 제공될 수
                    있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>②</p>
                <p class='modal_indent_content'>
                    회사는 시스템 정기점검 등 회사의 필요에 의하여 서비스 이용을
                    제한할 경우 사이트 화면에 게시하는 등의 방법으로 공지합니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>③</p>
                <p class='modal_indent_content'>
                    회사가 불가피한 사정에 의해 스크래핑 서비스를 폐지할 경우에는
                    사이트에 게시 하는 등의 방법으로 상당기간 안내 게시합니다. 다만,
                    회사 외적인 사유로 스크래핑 서비스 폐지가 불가피한 경우에는 안내
                    기간이 단축될 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>④</p>
                <p class='modal_indent_content'>
                    회사가 제 1 항 또는 제 2 항의 사유로 회원의 스크래핑 서비스
                    이용을 제한하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여
                    사이트에 공지합니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>⑤</p>
                <p class='modal_indent_content'>
                    회사는 전시, 사변, 천재지변 또는 이에 준하는 비상사태가
                    발생하거나 발생할 우려가 있는 경우와 그 밖의 불가항력적 사유가
                    있는 경우에는 제 4 항의 절차 없이 서비스의 전부 또는 일부를
                    제한하거나 중지할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>⑥</p>
                <p class='modal_indent_content'>
                    회사는 상기와 같은 이유로 스크래핑 서비스가 제한될 경우 회원에게
                    서비스에 필요한 정보를 팩스, 전자메일 등으로 요청할 수 있습니다.
                </p>
            </div>
            <br/>
            <br/>
            <p>제3장 서비스 이용계약</p>
            <p>제8조 (서비스 이용계약의 성립 등)</p>
            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    회사가 제공하는 서비스에 관한 이용계약(이하 “이용계약”이라고
                    합니다)은 회원이 되고자 하는 자가 본 약관에 동의하여 정해진
                    가입양식에 회원정보(ID, PASSWORD, 기타 “회사”가 필요하다고
                    인정하는 사항 등)를 기입하면 이를 이용신청으로 보고 “회사”가
                    이에 대해 승낙함으로써 성립합니다. 회사는 이용승낙의 의사를 해당
                    서비스 화면에 게시하거나, 전자메일 또는 기타 방법으로
                    이용신청자에게 통지합니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>②</p>
                <p class='modal_indent_content'>
                    회원에 가입하는 자는 회원가입 시에 “회사”가 서비스를 제공함에
                    있어 필요한 정보를 성실히 제공하여야 하며, “회사”가 특별히
                    요청할 경우 서류를 별도로 제출하여야 합니다. “회사”는
                    회원정보(ID, PASSWORD), 기타 “회사”가 필요하다고 인정하는 사항
                    등)을 접수 받아 이를 관리합니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>③</p>
                <p class='modal_indent_content'>
                    회원의 아이디(ID)는 1인당 1개의 아이디를 사용하는 것을 원칙으로
                    합니다. 다만, 투자자와 차입자가 동일할 경우 각각 다른 아이디를
                    사용할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>④</p>
                <p class='modal_indent_content'>
                    이 약관은 회사의 사이트에 게시하거나 기타의 방법으로 회원에게
                    공지함으로써 효력이 발생합니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>⑤</p>
                <p class='modal_indent_content'>
                    회원은 약관에 대하여 주의의무를 다하여야 하며, 변경된 약관의
                    부지로 인한 피해는 회사가 책임지지 않습니다. 회원이 서비스
                    이용을 신청하는 것은 “회사”가 제정한 “개인정보처리방침” 등 각종
                    정책 및 “회사”가 수시로 공지하는 사항을 준수하는 것에 대해
                    동의한 것으로 간주됩니다.
                </p>
            </div>

            <p>제9조 (회원가입 신청)</p>
            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    서비스를 이용하고자 하는 자는 회사가 온라인으로 제공하는
                    가입신청양식에 따라 회원정보를 기재함으로써 회원가입을
                    신청합니다. 회원 가입신청 시 기재사항은 차입자(대출 회원),
                    개인투자자, 법인투자자 여부에 따라 구분됩니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>②</p>
                <p class='modal_indent_content'>
                    회사는 회원에게 제 1항의 기재항목 외에도 별도 또는 추가적인
                    가입절차와 증빙 서류를 요청할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>③</p>
                <p class='modal_indent_content'>
                    회사는 회원이 사이트 및 서비스를 쉽게 이용할 수 있도록 회원의
                    아이디를 포함한 회원정보를 관리하거나 사이트 또는 서비스를 개선,
                    변경할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>④</p>
                <p class='modal_indent_content'>
                    회원이 사실과 다른 정보 또는 타인의 정보를 기입하거나 추후
                    그러한 정보임이 밝혀질 경우 운영자의 권한으로 회원의 자격을
                    일시정지하거나 영구정지 및 이용 계약을 해지할 수 있습니다. 이로
                    인하여 회사 또는 제3자에게 발생한 손해는 해당 회원이 책임을
                    집니다.
                </p>
            </div>

            <p>제10조 (회원가입의 변경)</p>
            <div class="modal_indent_div">
                <p class='modal_indent'>①</p>
                <p class='modal_indent_content'>
                    회원은 사이트를 통하여 언제든지 본인의 개인정보를 열람하고
                    수정할 수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>②</p>
                <p class='modal_indent_content'>
                    회사는 회원이 기재한 사항 중 중요하다고 회사가 판단하는 정보에
                    대해서 회사는 정보 변경을 위해 추가적인 본인 확인 절차를 요청 할
                    수 있습니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>③</p>
                <p class='modal_indent_content'>
                    회원은 회원가입신청시 기재한 사항이 변경되었을 경우 사이트를
                    통하여 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그
                    변경사항을 알려야 합니다.
                </p>
            </div>

            <div class="modal_indent_div">
                <p class='modal_indent'>④</p>
                <p class='modal_indent_content'>
                    전항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여
                    회사는 책임을 부담하지 않습니다.
                </p>
            </div>

        </div>
      </Modal>
    {/if}
    </div>
  