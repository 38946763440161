<script>
    import { Link } from "svelte-routing";
</script>

<header>
    <div class="container">
        <div class="top-gnb">
            <Link to="/">
                <img
                    class="logo-img"
                    src="/images/bi01_2x.png"
                    alt="logo"
                />
            </Link>
            <div class="main-header-mobile-button">
                <input type="checkbox" id="burger" />
                <label class="burger" for="burger">
                    <span />
                    <span />
                    <span />
                </label>

                <ul class="dropdown-content" role="menu">
                    <li>
                        <button class="main-header-mobile-link-button">
                            <a
                                class="main-header-mobile-link-button-text"
                                href="https://flowpay.kr"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p>매출채권 온라인관리</p>
                                <p>플로우포인트</p>
                            </a>
                            <span
                                class="main-header-mobile-link-button-arrow"
                                >➜</span
                            >
                        </button>
                    </li>
                    <li>
                        <button class="main-header-mobile-link-button">
                            <a
                                class="main-header-mobile-link-button-text"
                                href="https://flowpay.kr/main/service"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p>원자재 외상거래</p>
                                <p>플로우페이</p>
                            </a>
                            <span
                                class="main-header-mobile-link-button-arrow"
                                >➜</span
                            >
                        </button>
                    </li>
                </ul>
            </div>
            <!-- main header web button -->
            <div class="main-header-web-button">
                <li>
                    <button class="main-header-link-button">
                        <a
                            class="main-header-link-button-text"
                            href="https://flowpay.kr"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <p>매출채권 온라인관리</p>
                            <p>플로우포인트</p>
                        </a>
                        <span class="main-header-link-button-arrow">➜</span>
                    </button>
                </li>
                <li>
                    <button class="main-header-link-button">
                        <a
                            class="main-header-link-button-text"
                            href="https://flowpay.kr/main/service"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <p>원자재 외상거래</p>
                            <p>플로우페이</p>
                        </a>
                        <span class="main-header-link-button-arrow">➜</span>
                    </button>
                </li>
            </div>
        </div>
    </div>
</header>