<script>
    export let width = "600";
    export let height = "600";
</script>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100px" viewBox="-60 -20 {width} {height}" enable-background="new 0 0 164 164" xml:space="preserve">
     <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
     stroke="none">
     <path d="M2341 4941 c-11 -11 -25 -44 -32 -75 -7 -31 -14 -56 -16 -56 -3 0
     -30 14 -62 32 -44 25 -63 30 -86 24 -41 -10 -281 -250 -291 -291 -6 -23 -1
     -42 24 -86 18 -32 32 -59 32 -62 0 -2 -25 -9 -56 -16 -95 -22 -94 -20 -94
     -251 0 -231 -1 -229 94 -251 31 -7 56 -14 56 -16 0 -3 -14 -30 -32 -62 -25
     -44 -30 -63 -24 -86 10 -41 250 -281 291 -291 23 -6 42 -1 86 24 32 18 59 32
     62 32 2 0 9 -25 16 -56 18 -78 37 -94 111 -94 l60 0 0 -83 c0 -78 -2 -84 -24
     -95 -42 -19 -99 -76 -117 -117 -14 -30 -19 -65 -19 -135 l0 -95 -80 -120 c-59
     -89 -80 -129 -80 -153 0 -40 171 -386 195 -396 34 -13 85 -6 105 14 35 35 26
     79 -51 231 l-70 141 55 84 56 84 110 0 110 0 55 -83 56 -84 -76 -154 c-71
     -145 -75 -156 -64 -187 6 -18 19 -37 29 -42 21 -11 70 -13 95 -4 24 10 195
     356 195 396 0 25 -20 63 -77 148 l-78 115 -5 112 c-5 104 -7 114 -35 155 -17
     23 -52 57 -77 73 l-47 30 0 83 -1 82 60 0 c74 0 93 16 111 94 7 31 14 56 16
     56 3 0 30 -14 62 -32 44 -25 63 -30 86 -24 41 10 281 250 291 291 6 23 1 42
     -24 86 -18 32 -32 59 -32 62 0 2 25 9 56 16 95 22 94 20 94 251 0 231 1 229
     -94 251 -31 7 -56 14 -56 16 0 3 14 30 32 62 25 44 30 63 24 86 -10 41 -250
     281 -291 291 -23 6 -42 1 -86 -24 -32 -18 -59 -32 -62 -32 -2 0 -9 25 -16 56
     -22 95 -20 94 -251 94 -183 0 -202 -2 -219 -19z m333 -228 c12 -44 30 -91 40
     -102 30 -33 79 -27 159 20 l69 41 66 -65 65 -65 -41 -70 c-48 -81 -54 -128
     -21 -158 11 -10 58 -28 102 -40 l82 -20 0 -94 0 -94 -82 -20 c-44 -12 -91 -30
     -102 -40 -33 -30 -27 -79 20 -159 l41 -69 -65 -65 -65 -65 -69 41 c-80 47
     -129 53 -159 20 -10 -11 -28 -58 -40 -102 l-20 -82 -94 0 -94 0 -20 82 c-12
     44 -30 91 -40 102 -31 34 -79 27 -159 -21 l-69 -41 -65 65 -65 66 41 69 c47
     80 53 129 20 159 -11 10 -58 28 -102 40 l-82 20 0 94 0 94 82 20 c44 12 91 30
     102 40 33 30 27 79 -20 159 l-41 69 65 66 65 65 69 -41 c121 -73 167 -55 198
     78 10 41 20 79 22 83 3 4 46 6 96 5 l91 -3 20 -82z m-74 -1693 c22 -12 40 -60
     40 -106 l0 -34 -80 0 -80 0 0 34 c0 91 56 140 120 106z"/>
     <path d="M2432 4459 c-72 -28 -144 -101 -172 -176 -29 -78 -25 -206 9 -272 56
     -107 164 -171 291 -171 188 0 320 132 320 320 0 190 -132 321 -323 319 -47 0
     -90 -7 -125 -20z m206 -169 c130 -79 74 -280 -78 -280 -85 0 -150 65 -150 150
     0 54 27 102 72 130 20 12 51 20 78 20 27 0 58 -8 78 -20z"/>
     <path d="M295 4381 c-87 -40 -135 -120 -135 -223 0 -74 23 -129 71 -175 62
     -59 84 -63 339 -63 l230 0 0 -243 0 -244 -24 -11 c-42 -19 -99 -76 -117 -117
     -14 -30 -19 -65 -19 -135 l0 -95 -80 -120 c-59 -89 -80 -129 -80 -153 0 -40
     171 -386 195 -396 34 -13 85 -6 105 14 35 35 26 79 -51 231 l-70 141 55 84 56
     84 110 0 110 0 56 -84 55 -84 -70 -141 c-77 -152 -86 -196 -51 -231 20 -20 71
     -27 105 -14 24 10 195 356 195 396 0 25 -20 63 -77 148 l-78 115 -5 112 c-5
     104 -7 114 -35 155 -17 23 -52 57 -77 73 l-47 30 0 243 -1 242 315 0 315 0 0
     80 0 80 -590 0 c-490 0 -596 2 -625 14 -37 16 -52 48 -41 92 14 53 22 54 671
     54 l595 0 0 80 0 80 -632 0 c-595 0 -635 -2 -673 -19z m625 -1121 c22 -12 40
     -60 40 -106 l0 -34 -80 0 -80 0 0 34 c0 91 56 140 120 106z"/>
     <path d="M3510 4320 l0 -80 600 0 c655 0 662 -1 676 -54 11 -44 -4 -76 -41
     -92 -29 -12 -135 -14 -630 -14 l-595 0 0 -80 0 -80 320 0 320 0 0 -243 0 -244
     -24 -11 c-42 -19 -99 -76 -117 -117 -14 -30 -19 -65 -19 -135 l0 -95 -80 -120
     c-59 -89 -80 -129 -80 -153 0 -40 171 -386 195 -396 34 -13 85 -6 105 14 35
     35 26 79 -51 231 l-70 141 55 84 56 84 110 0 110 0 56 -84 55 -84 -70 -141
     c-77 -152 -86 -196 -51 -231 20 -20 71 -27 105 -14 24 10 195 356 195 396 0
     25 -20 63 -77 148 l-78 115 -5 112 c-5 104 -7 114 -35 155 -17 23 -52 57 -77
     73 l-47 30 0 243 -1 242 233 0 c259 0 281 5 344 71 44 47 63 97 63 169 0 101
     -49 181 -135 221 -38 17 -78 19 -677 19 l-638 0 0 -80z m770 -1060 c22 -12 40
     -60 40 -106 l0 -34 -80 0 -80 0 0 34 c0 91 56 140 120 106z"/>
     <path d="M980 1900 c-19 -19 -20 -33 -20 -320 l0 -300 -172 0 c-163 0 -177 -2
     -254 -28 -169 -59 -287 -177 -346 -346 -40 -116 -40 -256 0 -372 47 -135 140
     -244 261 -308 138 -72 -38 -66 2114 -66 l1938 0 76 25 c172 58 295 179 355
     349 24 69 28 97 28 186 0 89 -4 117 -28 186 -59 168 -178 287 -346 346 -79 27
     -87 28 -293 28 l-213 0 0 300 c0 287 -1 301 -20 320 -19 19 -33 20 -380 20
     -347 0 -361 -1 -380 -20 -19 -19 -20 -33 -20 -320 l0 -300 -160 0 -160 0 0
     300 c0 287 -1 301 -20 320 -19 19 -33 20 -380 20 -347 0 -361 -1 -380 -20 -19
     -19 -20 -33 -20 -320 l0 -300 -200 0 -200 0 0 300 c0 287 -1 301 -20 320 -19
     19 -33 20 -380 20 -347 0 -361 -1 -380 -20z m300 -220 l0 -80 80 0 80 0 0 80
     0 80 80 0 80 0 0 -240 0 -240 -240 0 -240 0 0 240 0 240 80 0 80 0 0 -80z
     m1200 0 l0 -80 80 0 80 0 0 80 0 80 80 0 80 0 0 -240 0 -240 -240 0 -240 0 0
     240 0 240 80 0 80 0 0 -80z m1120 0 l0 -80 80 0 80 0 0 80 0 80 80 0 80 0 0
     -240 0 -240 -240 0 -240 0 0 240 0 240 80 0 80 0 0 -80z m845 -570 c138 -20
     248 -99 307 -218 31 -62 33 -73 33 -172 0 -93 -3 -111 -27 -163 -34 -72 -121
     -160 -191 -192 -29 -14 -84 -29 -122 -35 -96 -13 -3674 -13 -3770 0 -38 6 -93
     21 -122 35 -70 32 -157 120 -191 192 -24 52 -27 70 -27 163 0 93 3 112 27 162
     56 119 175 208 303 227 87 12 3693 13 3780 1z"/>
     <path d="M855 941 c-87 -40 -135 -120 -135 -223 1 -142 97 -238 240 -238 144
     0 240 96 240 240 0 104 -53 187 -142 224 -52 22 -153 20 -203 -3z m155 -171
     c11 -11 20 -33 20 -50 0 -17 -9 -39 -20 -50 -11 -11 -33 -20 -50 -20 -17 0
     -39 9 -50 20 -11 11 -20 33 -20 50 0 17 9 39 20 50 11 11 33 20 50 20 17 0 39
     -9 50 -20z"/>
     <path d="M1655 941 c-87 -40 -135 -120 -135 -223 1 -142 97 -238 240 -238 144
     0 240 96 240 240 0 104 -53 187 -142 224 -52 22 -153 20 -203 -3z m155 -171
     c11 -11 20 -33 20 -50 0 -17 -9 -39 -20 -50 -43 -43 -120 -11 -120 50 0 38 32
     70 70 70 17 0 39 -9 50 -20z"/>
     <path d="M2455 941 c-87 -40 -135 -120 -135 -223 1 -142 97 -238 240 -238 144
     0 240 96 240 240 0 104 -53 187 -142 224 -52 22 -153 20 -203 -3z m155 -171
     c11 -11 20 -33 20 -50 0 -17 -9 -39 -20 -50 -43 -43 -120 -11 -120 50 0 38 32
     70 70 70 17 0 39 -9 50 -20z"/>
     <path d="M3255 941 c-87 -40 -135 -120 -135 -223 1 -142 97 -238 240 -238 144
     0 240 96 240 240 0 104 -53 187 -142 224 -52 22 -153 20 -203 -3z m155 -171
     c11 -11 20 -33 20 -50 0 -17 -9 -39 -20 -50 -43 -43 -120 -11 -120 50 0 38 32
     70 70 70 17 0 39 -9 50 -20z"/>
     <path d="M4055 941 c-87 -40 -135 -120 -135 -223 1 -142 97 -238 240 -238 144
     0 240 96 240 240 0 104 -53 187 -142 224 -52 22 -153 20 -203 -3z m155 -171
     c11 -11 20 -33 20 -50 0 -17 -9 -39 -20 -50 -43 -43 -120 -11 -120 50 0 38 32
     70 70 70 17 0 39 -9 50 -20z"/>
     </g>
</svg>