<script>
    import { Router, Link, Route } from "svelte-routing";
    import Home from "./routes/Home.svelte";
    import Esg from "./routes/Esg.svelte";

    export let url = "";
</script>

<Router {url}>
    <Route path="/esg" component={Esg} />
    <Route path="/"><Home /></Route>
    <!-- <Route path="/" component={Esg} /> -->
    <!-- <Route path="/"><Home /></Route> -->
</Router>
