<script>
    let articleList = [
        // Below is sample data
        [
            {
                context:
                    "중소기업 금융 전문 스타트업 ‘276홀딩스’의 신인근 대표는 인터뷰에서, 금리가 치솟은 가운데 금융권에서 기업평가가 대기업 중심적으로 이뤄지는 상황에 대해 이같이 밝혔다.",
                date: "2022-12-28",
                image: "https://cdn.nbntv.co.kr/news/photo/202212/999284_115321_2432.jpg",
                link: "http://www.sentv.co.kr/news/view/639297",
                press: "내외경제TV",
                title: "신인근 대표 '대기업과 평가 방법 달라야'",
            },
            {
                context:
                    "‘플로우포인트’는 독자적인 방식의 기업평가를 통해 채권 유동화시 적정 수준의 금리 범위를 안내하는 것이 특징으로 기존 기업 평가에서는 주로 재무건전성, 영속성, 성장성 등 장기적 관점에서 다뤘다면 플로우포인트는 단기적 관점에서 부실 위험도를 평가한다.",
                date: "2022-12-02",
                image: "https://photo.sentv.co.kr/photo/2022/12/07/20221207021128.png",
                link: "http://www.sentv.co.kr/news/view/639297",
                press: "SEN 서울경제TV",
                title: "276홀딩스, ‘플로우포인트’ 기업정보 서비스 개시",
            },
            {
                context:
                    "아시아에프앤아이가 지난 1일 서울 역삼동 한화 드림플러스 강남에서 '프롭핀테크 게임체인저 2기(PropFintech GameChangerⅡ)' 공모전 시상식을 개최했다고 2일 밝혔다.",
                date: "2022-12-02",
                image: "https://img.hankyung.com/photo/202212/01.32004418.1.jpg",
                link: "http://www.sentv.co.kr/news/view/639297",
                press: "한국경제신문",
                title: "276홀딩스 부동산플래닛 등 '신한퓨처스랩 프롭핀테크 게임체인저' 수상",
            },
        ],
        [
            {
                context:
                    "중소기업 금융 전문 스타트업 ‘276홀딩스’(대표 신인근)는 매출채권 온라인 관리 플랫폼 ‘플로우포인트’에서 기업정보 서비스를 개시한다고 밝혔다.",
                date: "2022-12-02",
                image: "https://www.sisunnews.co.kr/news/photo/202212/174604_332411_3714.png",
                link: "http://www.sisunnews.co.kr/news/articleView.html?idxno=174604",
                press: "시선뉴스",
                title: "276홀딩스, 기업채권 유동화정보 서비스 출시",
            },
            {
                context:
                    "매출채권 온라인 관리 서비스 전문 기업 276홀딩스(대표 신인근) 관계자에 따르면 ‘돈맥경화’라는 표현으로 이 현상을 설명했다. 시중에 자금이 없는 것은 아닌데 수요와 공급이 적절히 만나 흐르지 못하고 있는 상황을 단적으로 비유한 것이다.",
                date: "2022-11-07",
                image: "https://search.pstatic.net/common/?src=https%3A%2F%2Fimgnews.pstatic.net%2Fimage%2Forigin%2F5544%2F2022%2F11%2F07%2F835375.jpg&type=ofullfill264_180_gray&expire=2&refresh=true",
                link: "https://www.nbntv.co.kr/news/articleView.html?idxno=997194",
                press: "내외경제TV",
                title: "중소기업 운전자금 대출 앞으로의 방향성은?",
            },
            {
                context:
                    "중소기업 금융 전문 스타트업 276홀딩스가 매출채권의 온라인 관리 및 유동화 서비스를 제공하는 ‘플로우포인트 플랫폼’을 오픈했다고 24일 밝혔다. 최근 고물가·고환율과 더불어 인건비 부담 또한 높아지면서 자금난이 심화된 중소기업들의 유동화를 돕기 위한 플랫폼을 선보인 것.",
                date: "2022-10-25",
                image: "https://imgnews.pstatic.net/image/018/2022/10/25/0005349835_001_20221025144502358.jpg?type=w647",
                link: "https://n.news.naver.com/article/018/0005349835?sid=101",
                press: "이데일리",
                title: "276홀딩스, 매출채권 유동화 서비스 '플로우포인트' 선봬",
            },
        ],
        [
            {
                context: `신인근 276홀딩스 대표 "전자어음 할인 서비스 이어 매출채권 현금화 플랫폼 계획"`,
                date: "2020-12-23",
                image: "/images/news3.jpeg",
                link: "http://www.viva100.com/main/view.php?key=20201222010005349",
                press: "브릿지경제",
                title: `"코로나에 돈줄 막힌 중소기업, 혈액 뚫어드리죠"`,
            },
            {
                context:
                    "대기업에서 '대부업체 사장'으로. 전자어음 할인해 드립니다.",
                date: "2020-10-30",
                image: "/images/news2.jpg",
                link: "https://blog.naver.com/businessinsight/222129068314",
                press: "네이버 비즈니스",
                title: "276홀딩스 신인근 대표이사 인터뷰",
            },
            {
                context:
                    "276홀딩스, 탱커펀드, 센트비 등 3곳이 온라인으로 진행된 한국사회투자와 메트라이프생명 사회공헌재단의 임팩트투자 사회공헌 프로그램 데모데이 ‘딜 쉐어 라이브에서 최종 톱3기업으로 선정됐다",
                date: "2020-10-26",
                image: "/images/news1.jpg",
                link: "http://nanumy.co.kr/View.aspx?No=1302916",
                press: "나눔경제뉴스",
                title: "276홀딩스, 탱커펀드, 센트비 톱3 선정",
            },
        ],
    ];

    async function getNews() {
        try {
            const returnValue = await fetch(`/api/news`);
            const response = await returnValue.json();
            articleList = response.data;
        } catch (error) {
            console.error(error);
        }

        await (function ($) {
            $("#newsItems").carousel({
                interval: 5000,
                cycle: true,
            });
        })(jQuery);
    }

    const windowOpen = (link) => {
        window.open(link);
    };

    getNews();
</script>

<div
    class="content-section"
    style="background: linear-gradient(to bottom, transparent 39%, #f2ece8 39%); padding-top:1px; margin-top: 100px"
>
    <div class="header-container">
        <h3 style="text-align: center;">
            <a id="News">News</a>
        </h3>
        {#if articleList.length > 0}
            <!-- PC -->
            <div
                id="newsItems"
                class="carousel slide pt-4 d-none d-md-block"
                data-ride="carousel"
            >
                <div class="carousel-inner">
                    {#each articleList as articles, i}
                        <div
                            class="carousel-item {i === 0 ? 'active' : ''}"
                            data-interval="5000"
                        >
                            <div class="card-deck">
                                {#each articles as article}
                                    <div
                                        class="card col-md-4 news-card"
                                        style="cursor: pointer;"
                                        on:click={windowOpen(article.link)}
                                    >
                                        <img
                                            src={article.image === ""
                                                ? "error"
                                                : article.image}
                                            class="card-img-top news-image"
                                            alt="..."
                                            onerror="this.src = '/images/img.jpg'"
                                        />
                                        <div
                                            class="card-body"
                                            style="
                                                display: flex; flex-direction: column; align-items: center;
                                                padding: 0; margin-top: 18.5px; text-align:center;"
                                        >
                                            <small
                                                style="font-weight: 300; font-size:0.9rem"
                                            >
                                                [{article.press}]
                                            </small>
                                            <p
                                                class="card-title"
                                                style="font-size: 0.95rem; margin-bottom: 1px"
                                            >
                                                {#if article.title.length > 18}
                                                    {article.title.substring(
                                                        0,
                                                        18
                                                    )}...
                                                {:else}
                                                    {article.title}
                                                {/if}
                                            </p>
                                            <p
                                                class="card-text"
                                                style="font-size: 0.86rem; font-weight: 300"
                                            >
                                                {#if article.context.length > 40}
                                                    {article.context.substring(
                                                        0,
                                                        40
                                                    )}...
                                                {:else}
                                                    {article.context}
                                                {/if}
                                            </p>
                                        </div>
                                    </div>
                                {/each}
                            </div>
                        </div>
                    {/each}
                </div>

                {#if articleList[0].length >= 3}
                    <div
                        class="text-center news-button-div"
                        style="padding: 20px 0;"
                    >
                        <a
                            class="text-dark"
                            href="#newsItems"
                            data-slide="prev"
                        >
                            <svg
                                width="3em"
                                height="2em"
                                viewBox="0 0 16 16"
                                class="bi bi-chevron-left"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                />
                            </svg>
                        </a>
                        <a
                            class="text-dark"
                            href="#newsItems"
                            data-slide="next"
                        >
                            <svg
                                width="3em"
                                height="2em"
                                viewBox="0 0 16 16"
                                class="bi bi-chevron-right"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                />
                            </svg>
                        </a>
                    </div>
                {/if}
            </div>

            <!-- Mobile -->
            <div
                id="newsMobileItems"
                class="carousel slide d-md-none"
                data-ride="carousel"
            >
                <div class="carousel-inner p-3">
                    {#each articleList as articles, i}
                        {#each articles as article, j}
                            <div
                                class="carousel-item {i === 0 && j === 0
                                    ? 'active'
                                    : ''}"
                                data-interval="5000"
                            >
                                <div
                                    class="card news-card"
                                    style="cursor: pointer"
                                >
                                    <img
                                        src={article.image === ""
                                            ? "error"
                                            : article.image}
                                        class="card-img-top news-image"
                                        alt="..."
                                        onerror="this.src = '/images/img.jpg'"
                                    />
                                    <div class="card-body">
                                        <small class="text-muted"
                                            >[{article.press}]
                                        </small>
                                        <h5 class="card-title">
                                            {#if article.title.length > 18}
                                                {article.title.substring(
                                                    0,
                                                    18
                                                )}...
                                            {:else}
                                                {article.title}
                                            {/if}
                                        </h5>
                                        <p
                                            class="card-text"
                                            style="text-align: center; font-weight: 300;"
                                        >
                                            {#if article.context.length > 50}
                                                {article.context.substring(
                                                    0,
                                                    50
                                                )}...
                                            {:else}
                                                {article.context}
                                            {/if}
                                        </p>
                                        <p class="card-text">
                                            <small
                                                class="float-left btn btn-sm btn-dark"
                                                on:click={windowOpen(
                                                    article.link
                                                )}
                                                style="cursor: pointer"
                                                >상세보기</small
                                            >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        {/each}
                    {/each}
                </div>

                {#if articleList[0].length > 1}
                    <div class="text-center news-button-div">
                        <a
                            class="text-dark"
                            href="#newsMobileItems"
                            data-slide="prev"
                        >
                            <svg
                                width="3em"
                                height="2em"
                                viewBox="0 0 16 16"
                                class="bi bi-chevron-left"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                />
                            </svg>
                        </a>
                        <a
                            class="text-dark"
                            href="#newsMobileItems"
                            data-slide="next"
                        >
                            <svg
                                width="3em"
                                height="2em"
                                viewBox="0 0 16 16"
                                class="bi bi-chevron-right"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                />
                            </svg>
                        </a>
                    </div>
                {/if}
            </div>
        {:else}
            <div class="d-flex align-items-center p-4" style="height: 425px;">
                <h4>뉴스 정보를 가져오는 중입니다</h4>
                <div
                    class="spinner-border ml-auto"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        {/if}
    </div>
</div>
